<template>
  <div class="article-detail mb-6">
    <h1>{{ article.title }}</h1>
    <div class="d-flex align-center mb-4">
      <span class="article-class-chip rounded-pill px-6 py-1 mr-2">NEWS</span>
      <span class="article-pub-date">{{ article.pubDate }}</span>
    </div>
    <ebsn-media
      :cover="true"
      width="100%"
      :target="article"
      type="Article main"
      media="large"
      :alt="article.title"
    />

    <div
      v-if="article.articleType.name == 'recipe'"
      class="d-flex flex-justify-center mt-4"
    >
      <v-chip color="primary" class="article-course"> {{ course }} </v-chip>
      <v-spacer />
      <span>
        <v-icon class="mr-1">$clock</v-icon>
        {{ $ebsn.meta(article, "articletype.recipe_prep_time") }}
        {{ $ebsn.meta(article, "articletype.recipe_prep_time_text") }}
      </span>
    </div>
    <!-- <div
      v-else-if="article.articleType.name == 'post'"
      class="d-flex align-center flex-justify-center"
    >
      <v-chip-group>
        <v-chip
          v-for="category in categories"
          :key="category.id"
          color="primary"
          class="article-course"
        >
          {{ category.name }}
        </v-chip>
      </v-chip-group>
      <v-spacer />
      <div v-if="article.pubDate" class="small--text">
        {{ $dayjs(article.pubDate, "DD/MM/YYYY").format("DD MMMM YYYY") }}
      </div>
    </div> -->

    <div class="mt-3" v-html="article.content" @click="clicked"></div>
    <IngredientList
      v-if="article.articleType.name == 'recipe'"
      :key="article.articleId"
      class="mt-3"
      :ingredients="$ebsn.meta(article, 'articletype.recipe_ingredients')"
    />
    <div v-if="article.articleType.name == 'post'">
      <router-link
        class="d-flex align-center justify-sm-start mr-md-auto order-detail__actions--cta--back text-decoration-none"
        :to="{ path: '/rivista-e-news' }"
      >
        <v-icon class="mr-3 font-weight-bold" color="primary" size="30"
          >$arrowBack</v-icon
        >
        <span color="default" class="default--text back-btn">{{
          $t("articles.post.back")
        }}</span>
      </router-link>
    </div>
    <div v-else-if="article.articleType.name == 'recipe'">
      <router-link
        class="d-flex align-center justify-sm-start mr-md-auto order-detail__actions--cta--back text-decoration-none"
        :to="{ path: '/ricette' }"
      >
        <v-icon class="mr-3 font-weight-bold" color="primary" size="30"
          >$arrowBack</v-icon
        >
        <span color="default" class="default--text back-btn">{{
          $t("articles.recipe.back")
        }}</span>
      </router-link>
    </div>
  </div>
</template>
<style lang="scss">
.article-detail {
  h1 {
    color: #2b2f31;
    font-size: 28px !important;
    font-weight: 500 !important;
  }
  .back-btn {
    font-weight: 500;
  }
  .article-class-chip {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #d9000d;
    font-weight: 500;
    font-size: 16px;
    color: #414548;
  }
  .article-pub-date {
    font-size: 16px;
    color: #3d3d3d;
  }
  img {
    max-width: 100%;
    height: auto;
  }
}
</style>
<script>
import IngredientList from "@/components/article/IngredientList.vue";
import clickHandler from "~/mixins/clickHandler";

export default {
  mixins: [clickHandler],
  components: {
    IngredientList
  },
  props: {
    article: { type: Object, required: true }
  },
  computed: {
    templateName() {
      return "Static";
      // if (this.page.template && this.page.template == "page-leftsidebar.php") {
      //   return "PageLeftsidebar";
      // } else {
      //   return "Static";
      // }
    },
    course() {
      return this.article?.articleClass.find(
        ac => ac.articleClassGroup.name == "course"
      )?.name;
    },
    categories() {
      return this.article?.articleClass?.filter(
        ac => ac.articleClassGroup.name == "category"
      );
    }
  }
};
</script>
